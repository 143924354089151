<script lang="ts" setup>
import { TheSnackbar } from '@/components';

const route = useRoute();

const hasBackground = computed((): boolean => {
  return !['frankieone-verification'].includes(route.name);
});
</script>

<template>
  <v-layout>
    <v-main
      :class="{
        'v-main--bg': hasBackground,
      }"
    >
      <v-row justify="center" no-gutters>
        <v-col class="pa-0">
          <NuxtPage :page-key="(route) => route.fullPath" :transition="false" />
        </v-col>
      </v-row>
    </v-main>
    <TheSnackbar />
  </v-layout>
</template>

<style lang="scss" scoped>
.v-main {
  padding-block: 80px;

  .v-col {
    max-width: var(--form-max-width);
  }
}

.v-main--bg {
  background-color: #f6f6f6;
}
</style>
